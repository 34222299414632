<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters style="flex-wrap: nowrap">
      <v-col cols="1" class="flex-grow-0 flex-shrink-0 text-center">
        <v-card v-if="!mine && !sameSource" class="text-center" flat>
          <v-avatar color="secondary" size="56">
            <v-img :src="getAvatar"></v-img>
          </v-avatar>
          <div class="body-2">{{ username }}</div>
        </v-card>
        <v-spacer />
      </v-col>

      <!-- align-self: baseline;  -->
      <v-col
        cols="10"
        :class="`flex-grow-1 flex-shrink-0 ${mine ? 'mb-1' : 'mb-2'}`"
        :style="`min-width: 100px; max-width: 100%; display: flex; ${mine ? 'flex-direction: row-reverse' : ''};`"
      >
        <v-card
          flat
          :class="`d-flex align-start pa-2 ${mine ? 'mr-3' : 'ml-3'}`"
          :color="getColor"
        >
          <v-icon
            v-if="!mine && !sameSource"
            large
            style="position: absolute; left: -20px"
            :color="getColor"
          >
            mdi-menu-left
          </v-icon>
          <div class="px-1 pb-1">
            {{ content }}
          </div>
          <div :class="`${getTimeColor} mt-auto`" style="font-size: 8pt; margin-bottom: -8px; margin-left: -8px;">{{ getTime }}</div>
          <v-icon
            v-if="mine && !sameSource"
            large
            style="position: absolute; right: -20px"
            :color="getColor"
          >
            mdi-menu-right
          </v-icon>
        </v-card>
      </v-col>

      <v-col cols="1" class="flex-grow-0 flex-shrink-0 text-center d-flex flex-column">
        <v-card v-if="mine && !sameSource" class="text-center" flat>
          <v-avatar color="secondary" size="56">
            <v-img :src="getAvatar"></v-img>
          </v-avatar>
          <div class="body-2">username{{ username }}</div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';

export default {
  name: "ChatMessage",
  props: {
    mine: Boolean,
    sameSource: Boolean,
    source: String,
    avatar: String,
    content: String,
    time: String,
  },
  computed: {
    getTime() {
      return this.time ? moment(this.time).format("yyyy-MM-DD hh:mm A") : "";
    },
    getColor() {
      return this.mine ? "secondary lighten-1" : "grey lighten-3";
    },
    getTimeColor() {
      return this.mine ? "secondary--text text--lighten-4" : "grey--text text--darken-1";
    },
    getAvatar() {
      return `${this.getApiBaseUrl}/files/avatar/${this.source}`;
    },
  },
};
</script>
