<template>
  <div>
    <v-divider v-if="!hideDivider && !reverse" class="grey lighten-3" />
    <div :class="`mb-2 d-flex justify-space-between ${reverse ? 'flex-row-reverse' : ''}`">
      <v-sheet
        class="px-2 py-0"
        color="primary"
        :style="`display: inline-block; margin-left:-20px; margin-right: -20px; border-bottom-${!reverse ? 'right' : 'left'}-radius: 10px;`"
      >
        <h4 :id="bookmark" class="secondary--text text-uppercase font-weight-regular px-3">
          {{ title }}
          <v-icon v-if="icon" color="secondary" small class="mb-1">{{
            icon
          }}</v-icon>
        </h4>
      </v-sheet>
      <span class="primary--text text-uppercase font-weight-bold mt-1 mr-4">
        <slot></slot>
      </span>
    </div>
    <v-divider v-if="!hideDivider && reverse" />
  </div>
</template>

<script>
export default {
  name: "SectionHeader",
  props: {
    title: String,
    icon: String,
    bookmark: String,
    reverse: Boolean,
    hideDivider: Boolean,
  },
};
</script>
