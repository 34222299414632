<template>
  <div>
    <div v-if="header" :style="`height: ${headerHeight}; max-height: ${headerHeight};`">
      <slot name="header" />
    </div>

    <v-divider />

    <div class="pa-2" :style="`${getViewHeightStyle(offset + headerHeight + footerHeight, 0)}; overflow-y: auto;`">
      <slot />
    </div>

    <div v-if="footer" style="position: absolute; bottom: 0; width: 100%;">
      <div :style="`height: ${footerHeight}; max-height: ${footerHeight};`">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StickyStackLayout",
  props: {
    header: {
      type: Boolean,
      default() {
        return true;
      }
    },
    footer: {
      type: Boolean,
      default() {
        return true;
      }
    },
    offset: {
      type: Number,
      default() {
        return 0;
      }
    },
    headerHeight: {
      type: Number,
      default() {
        return this.header ? 64 : 0;
      }
    },
    footerHeight: {
      type: Number,
      default() {
        return this.footer ? 72 : 0;
      }
    },
  },
  mounted() {
    console.log("Offset", this.offset + this.headerHeight + this.footerHeight);
  }
};
</script>
