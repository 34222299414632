<template>
  <!-- <v-container> -->
    <!-- <p>{{ params.body }}</p> -->
    <v-form ref="form">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('general.search')"
        hide-details
        dense
        class="mb-3 pa-2"
      ></v-text-field>
      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :search="search"
          :value="selection"
          :items="getDrivers"
          item-key="id"
          show-select
          dense
          @item-selected="selectionChanged"
        >
          <template #header.data-table-select="{ header }"></template>

          <template #item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              v-model="item.selected"
              :disabled="item.dispatched"
              on-icon="mdi-checkbox-marked-circle"
              off-icon="mdi-power-off"
              color="primary"
              :ripple="false"
              :value="isSelected"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>

          <template #item.username="{ item }">
            <v-avatar size="32" class="my-1 mr-1">
              <img v-if="item.avatar" alt="Avatar" :src="item.avatar" />
              <v-icon v-else large color="primary">{{
                userInitials(item.username)
              }}</v-icon>
            </v-avatar>
            <span
              :class="
                !item.enabled ? 'text-decoration-line-through red--text' : ''
              "
              >{{ item.username }}</span
            >
            <v-icon v-if="!item.enabled" color="red" small
              >mdi-account-cancel</v-icon
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-form>
  <!-- </v-container> -->
</template>

<script>
export default {
  name: "SelectDriversDialog",
  model: {
    prop: "params",
    event: "input",
  },
  props: {
    params: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      search: null,
      headers: [
        { text: this.$t("user.username"), value: "username" },
        { text: this.$t("general.name"), value: "name" },
      ],
    };
  },
  computed: {
    selection() {
      const s = this.params.onDuty;
      return s ? [s] : [];
    },
    getDrivers() {
      const drivers = this.params.drivers.map((d) => ({
        ...d,
        selected: this.params.onDuty.includes(d.id),
        dispatched: this.params.dispatched.includes(d.id),
      }));

      return drivers;
    },
  },
  methods: {
    selectionChanged(event) {
      if (!this.params.onDuty.includes(event.item.id)) {
        this.params.onDuty = [...this.params.onDuty, event.item.id];
      } else {
        this.params.onDuty = this.params.onDuty.filter(
          (f) => f !== event.item.id
        );
      }
    },
    userInitials(name) {
      if (name && name.length > 0) {
        return `mdi-alpha-${name.toLowerCase()[0]}-circle-outline`;
      }
      return "mdi-account-circle";
    },
  },
};
</script>
