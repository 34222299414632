<template>
  <view-container :scroll-target="scrollTarget">
    <div
      style="position: absolute;
    left: 170px;
    z-index: 9;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee"
    >
      <v-btn-toggle tile v-model="view" color="primary" borderless>
        <v-btn @click="updateQuery(0)">
          Loading
        </v-btn>
        <v-btn @click="updateQuery(1)">
          Dispatched
        </v-btn>
      </v-btn-toggle>
    </div>
    <dispatcher
      v-if="view === 0"
      @scrolled="(e) => (scrollTarget = e)"
    />
    <dispatched-orders
      v-if="view === 1"
      @scrolled="(e) => (scrollTarget = e)"
    />
  </view-container>
</template>

<script>
import Dispatcher from "@/views/admin/orders/Dispatcher";
import DispatchedOrders from "@/views/admin/orders/DispatchedOrders";
import ViewContainer from "@/common/layouts/ViewContainer";

export default {
  name: "OrdersManager",
  components: { ViewContainer, Dispatcher, DispatchedOrders },
  data() {
    return {
      scrollTarget: null,
      view: 0,
    };
  },
  mounted() {
    this.view = parseInt(this.$route.query.view) || 0;
    // const topics = this.$generateStoreTopics(this.getStoreIdParam);
    // this.subscribe(topics);
  },
  // beforeDestroy() {
  //   const topics = this.$generateStoreTopics(this.getStoreIdParam);
  //   this.unsubscribe(topics);
  // },
  methods: {
    updateQuery(view) {
      this.$router.push({ query: { view }});
    }
  },
};
</script>
