import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"width":"100%","flat":"","color":"transparent"}},[_c(VCard,{style:(("border-radius: 5px 5px 0 0; border: 1px solid #d0f3c5; border-bottom: 0; background-size: 28px 28px; background: " + (_vm.selected ? '#d7feba' : 'white'))),attrs:{"flat":"","width":"300","height":"47"}},[_c(VCardActions,{staticClass:"py-0 pl-1 pr-2"},[(!_vm.isActive)?_c(VBtn,{attrs:{"depressed":"","small":"","fab":"","color":"transparent"},on:{"click":function($event){return _vm.$emit('select')}}},[(_vm.selected)?_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-radiobox-marked")]):_vm._e(),(!_vm.selected)?_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-radiobox-blank")]):_vm._e()],1):_vm._e(),_c(VListItem,{staticClass:"ma-0 pa-0",on:{"click":function($event){return _vm.$emit('click:driver')}}},[_c(VListItemAvatar,{staticClass:"my-0 mx-1",attrs:{"color":"grey darken-3"}},[_c(VImg,{attrs:{"src":_vm.getAvatar}})],1),_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.driver.name))])],1)],1),_c(VChip,{staticClass:"pr-4",attrs:{"label":"","small":"","color":"white"}},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.ordersLoad))]),_c(VIcon,{staticClass:"ml-1",attrs:{"small":""}},[_vm._v(" mdi-home-group ")])],1),_c(VBtn,{staticClass:"ml-2",attrs:{"depressed":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('click:dispach')}}},[_c(VIcon,[_vm._v(" mdi-play ")])],1)],1)],1),_c(VCard,{staticClass:"pa-1",style:(("border-radius: 0 5px 5px 5px; border: 1px solid #d0f3c5; background: " + (_vm.selected
        ? 'repeating-linear-gradient(45deg, #fff, #fff 5px, #d7feba 5px, #d7feba 10px);'
        : 'white'))),attrs:{"flat":"","width":"100%","min-height":"30"},on:{"drop":function($event){return _vm.$emit('drop')},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }