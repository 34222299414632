<template>
  <div
    :style="`background: rgba(255, 255, 255, 0.01);
    position: absolute;
    ${left ? 'left: 0px;' : ''}
    ${right ? 'right: 0px;' : ''}
    ${top ? 'top: 0px;' : ''}
    ${bottom ? 'bottom: 0px;' : ''}
    height: ${height || '16'}px;
    width: ${width || '87'}px; z-index: 9999; cursor: default;`"
  ></div>
</template>

<script>
export default {
  name: "Unclickable",
  props: {
    height: Number,
    width: Number,
    left: Boolean,
    right: Boolean,
    top: Boolean,
    bottom: Boolean,
  },
}
</script>
