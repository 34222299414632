<template>
  <v-container class="pa-0" fluid>
    <v-card flat>
      <toolbar :title="$t('admin.route.orders')">
        <v-text-field
          class="d-none d-md-flex mx-1"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
          dense
          style="width: 0"
          clearable
        ></v-text-field>
        <v-divider vertical />
        <v-btn text x-small color="primary" @click="selectDrivers()">
          <v-icon>mdi-truck-delivery</v-icon>
          <span class="text--secondary">{{ $t("driver.drivers") }}</span>
        </v-btn>
        <v-divider vertical />
        <v-btn text x-small color="primary" @click="expandAll()">
          <v-icon>mdi-chevron-down</v-icon>
          <span class="text--secondary">{{ $t("general.expandAll") }}</span>
        </v-btn>
        <v-btn text x-small color="primary" @click="collapseAll()">
          <v-icon>mdi-chevron-up</v-icon>
          <span class="text--secondary">{{ $t("general.collapseAll") }}</span>
        </v-btn>
      </toolbar>

      <v-card-text class="pa-0" :style="getViewHeightStyle(84)">
        <v-container
          ref="dispatchContainer"
          @scroll.self="onScroll"
          class="dispatch-table-container"
        >
          <app-drawer v-model="detailsDrawer" @closed="hideOrderDetails">
            <order-details
              v-model="selectedOrder"
              history
              customer
              driver
              support
              class="pb-40"
            />
          </app-drawer>

          <table ref="dispatchContent" class="dispatch-table" :style="getInitTableHeight">
            <thead>
              <tr>
                <th class="waiting">
                  <v-toolbar dense flat color="grey lighten-5">
                    <v-toolbar-title class="body-1">
                      <v-icon class="mx-1" color="blue"> mdi-timer-outline </v-icon>
                      <strong>WAITING</strong>
                    </v-toolbar-title>
                    <v-card class="px-3 py-1 ma-2" flat color="secondary">
                      {{ getWaiting.length }}
                    </v-card>
                  </v-toolbar>
                </th>
                <th class="preparing">
                  <v-toolbar dense flat color="transparent">
                    <v-toolbar-title class="body-1">
                      <v-icon class="mx-1" color="orange"> mdi-basket-fill </v-icon>
                      <strong>PREPARING</strong>
                    </v-toolbar-title>
                    <v-card class="px-3 py-1 ma-2" flat color="secondary">
                      {{ getPreparing.length }}
                    </v-card>
                  </v-toolbar>
                </th>
                <th class="dispatched">
                  <v-toolbar dense flat color="transparent">
                    <v-toolbar-title class="body-1">
                      <v-icon class="mx-1" color="green lighten-1">
                        mdi-truck-delivery
                      </v-icon>
                      <strong>LOADING</strong>
                    </v-toolbar-title>
                    <v-card class="px-3 py-1 ma-2" flat color="secondary">
                      {{ getLoading.length }}
                    </v-card>
                  </v-toolbar>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  class="waiting"
                  @drop="endDrag('WAITING')"
                  @dragover.prevent
                  @dragenter.prevent
                >
                  <div class="d-flex flex-wrap">
                    <dispatch-card
                      v-for="(order, i) in getWaiting"
                      :key="i"
                      type="Waiting"
                      :order="order"
                      :selected="selectedOrder && selectedOrder.id === order.id"
                      :collapsed="order.collapsed"
                      @click:details="showOrderDetails(order)"
                      @click:next="setStatus(order, 'PREPARING')"
                      @click:reject="setStatus(order, 'NOT ACCEPTED')"
                      @dragstart.native="startDrag($event, order)"
                      draggable
                      :fade="notFoundInSreach(order)"
                    />
                  </div>
                </td>
                <td
                  class="preparing"
                  @drop="endDrag('PREPARING')"
                  @dragover.prevent
                  @dragenter.prevent
                >
                  <v-card class="d-flex flex-wrap" flat color="transparent">
                    <dispatch-card
                      v-for="(order, i) in getPreparing"
                      :key="i"
                      type="Preparing"
                      :order="order"
                      :selected="selectedOrder && selectedOrder.id === order.id"
                      @click:details="() => showOrderDetails(order)"
                      @click:customer="() => showCustomerInfo(order)"
                      @click:back="setStatus(order, 'WAITING')"
                      @click:next="setLoading(order.id)"
                      @click:cancel="setStatus(order, 'CANCELLED')"
                      :collapsed="order.collapsed"
                      @dragstart.native="startDrag($event, order)"
                      draggable
                      :fade="notFoundInSreach(order)"
                    />
                  </v-card>
                </td>
                <td class="dispatched">
                  <v-alert
                    color="secondary lighten-3"
                    dense
                    class="body-2 primary--text"
                    v-if="getOnDutyAndFreeDrivers.length === 0"
                  >
                    <v-icon color="warning">mdi-alert</v-icon> No driver on duty selection
                    yet, please select one
                  </v-alert>
                  <v-card
                    v-for="(driver, d) in getOnDutyAndFreeDrivers"
                    :key="d"
                    width="100%"
                    class="d-flex justify-start align-start my-1"
                    flat
                    style="background-color: transparent"
                  >
                    <delivery-card
                      :driver="driver"
                      :orders-load="getLoadingByDriver(driver.id).length"
                      @click:dispach="dispatchDelivery(driver)"
                      @click:driver="() => showDriverInfo(driver)"
                      @select="() => (selectedDriverUuid = driver.uuid)"
                      :selected="driver.uuid === selectedDriverUuid"
                      @drop="endDrag('LOADING', driver.uuid)"
                    >
                      <div class="d-flex flex-wrap">
                        <dispatch-card
                          v-for="(order, i) in getLoadingByDriver(driver.id)"
                          :key="i"
                          :order="order"
                          :selected="selectedOrder && selectedOrder.id === order.id"
                          :collapsed="order.collapsed"
                          @click:details="showOrderDetails(order)"
                          @click:customer="showCustomerInfo(order)"
                          @click:cancel="setStatus(order, 'CANCELLED')"
                          @click:back="setStatus(order, 'PREPARING')"
                          @dragstart.native="startDrag($event, order)"
                          :draggable="order.activeTrackStatus === 'LOADING'"
                          :fade="notFoundInSreach(order)"
                        />
                        <!-- :collapsed="collapsedItems.includes(order.id)" -->
                      </div>
                    </delivery-card>
                  </v-card>
                </td>
              </tr>
            </tbody>
          </table>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toolbar from "@/common/components/Toolbar";
import DispatchCard from "@/views/admin/orders/DispatchCard";
import DeliveryCard from "@/views/admin/orders/DeliveryCard";
import {
  STATUS_WAITING,
  STATUS_CANCELLED,
  STATUS_NOT_ACCEPTED,
  STATUS_PREPARING,
  STATUS_LOADING,
  STATUS_DISPATCHED,
  STATUS_NEAR,
  STATUS_DELIVERY,
  STATUS_DELIVERED,
} from "@/store/actions/type.orders";
import AppDrawer from "@/common/components/AppDrawer.vue";
import OrderDetails from "@/views/orders/OrderDetails";
import SelectDriversDialog from "@/views/admin/orders/SelectDriversDialog";

export default {
  name: "Dispatcher",
  components: {
    Toolbar,
    DispatchCard,
    DeliveryCard,
    AppDrawer,
    OrderDetails,
    SelectDriversDialog,
  },
  data() {
    return {
      selectedDriverUuid: null,
      dragedOrder: null,
      loading: {
        activeOrders: false,
      },
      collapsedItems: [],
      search: null,
      detailsDrawer: false,
      selectedOrder: null,
    };
  },
  mounted() {
    this.loading.activeOrders = false;
    this.loadActiveOrders(this.getStoreIdParam).then(() => {
      this.loading.activeOrders = true;
    });

    // 4: drivers
    this.loadStoreUsersByType({
      storeId: this.getStoreIdParam,
      type: "DRIVER",
    });
  },
  computed: {
    ...mapGetters("orders", [
      "getActiveOrders",
      "getActiveOrdersByStatus",
      "getActiveOrdersByDriver",
      "getSelectedOrder",
    ]),
    ...mapGetters("ui", ["getLanguage"]),
    ...mapGetters("users", ["getUsersByProp", "getDriversOnDuty"]),

    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },

    getOnDutyAndFreeDrivers() {
      console.log("getDispatchedDrivers:", this.getDispatchedDrivers);
      console.log("getActiveOrders:", this.getActiveOrders);
      if (this.notEmptyArray(this.getActiveOrders)) {
        return this.getDriversOnDuty.filter(
          (f) => !this.getDispatchedDrivers.some((s) => s.id === f.id)
        );
      } else {
        return this.getDriversOnDuty;
      }
    },
    getDispatchedDrivers() {
      const drivers = this.getUsersByProp("typeName", "DRIVER");
      const orders = this.getActiveOrders;
      if (this.notEmptyArray(drivers) && this.notEmptyArray(orders)) {
        return (
          drivers.filter((f) =>
            orders.every(
              (s) =>
                ["DISPATCHED", "DELIVERY", "NEAR"].includes(s.statusName) &&
                s.driverUuid === f.uuid
            )
          ) || []
        );
      } else {
        return [];
      }
    },
    getWaiting() {
      return this.getActiveOrdersByStatus(STATUS_WAITING);
    },
    getCancelled() {
      return this.getActiveOrdersByStatus(STATUS_CANCELLED);
    },
    getPreparing() {
      return this.getActiveOrdersByStatus(STATUS_PREPARING);
    },
    getLoading() {
      return this.getActiveOrdersByStatus(STATUS_LOADING);
    },
    getDispatched() {
      return this.getActiveOrdersByStatus(STATUS_DISPATCHED);
    },
    getDeilvery() {
      return this.getActiveOrdersByStatus(STATUS_DELIVERY);
    },
    getNear() {
      return this.getActiveOrdersByStatus(STATUS_NEAR);
    },
    getDelivered() {
      return this.getActiveOrdersByStatus(STATUS_DELIVERED);
    },
    getNotAccepted() {
      return this.getActiveOrdersByStatus(STATUS_NOT_ACCEPTED);
    },
    isLoaded() {
      return this.loading.every((e) => e);
    },
    getInitTableHeight() {
      return this.getViewHeightStyle(84);
    },
  },
  methods: {
    ...mapActions("types", ["loadTypes"]),
    ...mapActions("orders", [
      "loadActiveOrders",
      "updateOrderStatus",
      "setSelectedOrder",
      "clearSelectedOrder",
      "collapseAll",
      "expandAll",
    ]),
    ...mapActions("users", [
      "loadStoreUsersByType",
      "setDriversOnDuty",
      "loadDispatchedDrivers",
      "setDriverStatus",
      "startTrack",
    ]),
    ...mapActions("ui", ["showDialog", "closeDialog", "showFeedback"]),
    ...mapActions("socket", ["subscribe", "unsubscribe"]),

    getActiveOrderById(id) {
      return this.getActiveOrders.filter((f) => f.id === id);
    },
    getTrackId(orderId, status) {},
    toggleCollapse(id) {
      if (!this.collapsedItems.includes(id)) {
        this.collapsedItems.push(id);
      } else {
        this.collapsedItems = this.collapsedItems.filter((f) => f !== id);
      }
    },
    getLoadingByDriver(driverId) {
      return this.notEmptyArray(this.getLoading)
        ? this.getLoading.filter((o) => o.driverId === driverId)
        : [];
    },

    showOrderDetails(order) {
      this.selectedOrder = order;
      this.detailsDrawer = true;
    },
    hideOrderDetails() {
      this.detailsDrawer = false;
      this.selectedOrder = null;
    },
    showCustomerInfo(customer) {},
    showDriverInfo(driver) {},

    setStatus(order, status) {
      const params = {
        orderUuid: order.uuid,
        driverUuid: order.driverUuid,
        status,
        storeId: this.getStoreIdParam,
      };
      this.updateOrderStatus(params);
    },
    setLoading(orderUuid) {
      if (this.selectedDriverUuid) {
        this.updateOrderStatus({
          orderUuid,
          driverUuid: this.selectedDriverUuid,
          status: STATUS_LOADING,
          storeId: this.getStoreIdParam,
        });
      } else {
        this.showFeedback({
          show: true,
          title: "errors.error",
          text: "messages.selectDriver",
          timeout: 3000,
          color: "red lighten-2",
          // light: true,
        });
      }
    },
    onScroll(e) {
      this.$emit("scrolled", {
        value: e.target.scrollTop,
        target: this.$refs.dispatchContent,
        container: this.$refs.dispatchContainer,
      });
    },
    selectDrivers() {
      console.log(this.getActiveOrders);
      const params = {
        drivers: this.getUsersByProp("typeName", "DRIVER"),
        onDuty: this.getDriversOnDuty.map((m) => m.id),
        dispatched: this.getDispatchedDrivers.map((m) => m.id),
        storeId: this.getStoreIdParam,
        body: this.$t("messages.selectDrivers"),
      };
      const dialog = {
        title: this.$t("driver.drivers"),
        component: SelectDriversDialog,
        noGutter: true,
        // fullscreen: true,
        params,
        width: 450,
        actions: [
          {
            text: this.$t("general.okay"),
            click: ({ onDuty }) => {
              const list = this.getUsersByProp("typeName", "DRIVER");
              const selected =
                this.notEmptyArray(onDuty) && this.notEmptyArray(list)
                  ? [
                      ...list.filter((f) => onDuty.includes(f.id)),
                      ...this.getDispatchedDrivers,
                    ]
                  : [...this.getDispatchedDrivers];

              this.setDriversOnDuty(selected);

              if (this.notEmptyArray(selected)) {
                this.selectedDriverUuid = selected[0].uuid;
              }

              this.closeDialog();
            },
            color: "primary",
          },
          {
            text: this.$t("general.cancel"),
            click: () => this.closeDialog(),
            color: "primary",
          },
        ],
      };
      this.showDialog(dialog);
    },
    dispatchDelivery(driver) {
      // this.setDriverStatus({ uuid: driver.uuid, status: STATUS_DISPATCHED });
      const orders = this.getLoadingByDriver(driver.id);
      for (let i in orders) {
        this.updateOrderStatus({
          orderUuid: orders[i].uuid,
          driverUuid: driver.uuid,
          status: STATUS_DISPATCHED,
          storeId: this.getStoreIdParam,
        });
      }
      this.startTrack(driver.uuid);
    },
    startDrag(e, order) {
      e.dataTransfer.dropEffect = "move";
      e.dataTransfer.effectAllowed = "move";
      this.dragedOrder = order;
    },
    endDrag(status, driverUuid) {
      switch (status) {
        case STATUS_WAITING:
          if (this.dragedOrder.activeTrackStatus === STATUS_PREPARING) {
            this.setStatus(this.dragedOrder, status);
          }
          break;
        case STATUS_PREPARING:
          if (
            this.dragedOrder.activeTrackStatus === STATUS_WAITING ||
            this.dragedOrder.activeTrackStatus === STATUS_LOADING
          ) {
            this.setStatus(this.dragedOrder, status);
          }
          break;
        case STATUS_LOADING:
          if (this.dragedOrder.activeTrackStatus === STATUS_PREPARING) {
            if (driverUuid) {
              this.selectedDriverUuid = driverUuid;
            }
            this.setLoading(this.dragedOrder.uuid);
          }
          break;
      }
      this.dragedOrder = null;
    },
    isLastDriverOrder(driverUuid, orderId) {
      if (!driverUuid) {
        return false;
      }

      const orders = this.getActiveOrdersByDriver(driverUuid);
      return (
        orders &&
        !orders.some(
          (f) =>
            [
              STATUS_LOADING,
              STATUS_DISPATCHED,
              STATUS_DELIVERY,
              STATUS_NEAR,
              STATUS_DELIVERED,
            ].includes(f.status) && f.orderId !== orderId
        )
      );
    },

    notFoundInSreach(order) {
      return (
        !!this.search &&
        `#${order.orderId}` +
          `#${order.customerName}` +
          `#${order.customerPhone}` +
          `#${order.customerEmail}` +
          `#${order.customerAddresses ? order.customerAddresses.area : ""}` +
          `#${order.driverName}` +
          `#${order.driverPhone}` +
          `#${order.driverEmail}`.indexOf(this.search.toLocaleLowerCase()) >=
          0
      );
    },
  },
};
</script>

<style scoped>
.dispatch-table-container {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-y: auto;
  max-width: 100%;
}

.dispatch-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border: 1px solid #eee;
}
.dispatch-table th {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.dispatch-table tbody > tr:not(:last-child) > td:first-child {
  border-bottom: 2px dotted #ddd;
}

.dispatch-table tbody > tr:not(:last-child) > td:not(:first-child) {
  border-bottom: 2px dotted #fff;
}

.dispatch-table .waiting {
  background-color: #fff;
  width: 220px !important;
}

.dispatch-table .preparing {
  border-left: 2px dotted #ddd;
  background-color: #ffffe4;
  width: 220px !important;
}

.dispatch-table .dispatched {
  border-left: 2px dotted #ddd;
  background-color: #f0ffe5;
}

.dispatch-table td {
  padding: 10px;
  vertical-align: top;
}
</style>
