import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"ma-1",attrs:{"color":_vm.selected ? 'primary' : '',"width":"200","hover":"","tile":_vm.selected}},[_c(VSheet,{class:("d-flex card-title " + (_vm.selected ? 'primary' : _vm.getStatusTypeColor(_vm.order.activeTrackStatus))),style:(("height: 35px; " + (_vm.fade ? 'filter: grayscale(0.9); opacity: 0.5;' : '')))},[_c('img',{staticStyle:{"margin-left":"2px"},attrs:{"height":"28","src":_vm.getAvatar}}),_c('div',{class:("ma-1 " + (_vm.selected || _vm.isDarkStatusTheme(_vm.order.activeTrackStatus)
          ? 'white--text'
          : 'black--text'))},[_vm._v(" "+_vm._s(_vm.order.customerName)+" ")]),_c(VSpacer),_c(VBtn,{staticStyle:{"margin-top":"3px"},attrs:{"small":"","depressed":"","color":"white","icon":""},on:{"click":function($event){_vm.collapsed = !_vm.collapsed}}},[(_vm.collapsed)?_c(VIcon,[_vm._v("mdi-chevron-down")]):_vm._e(),(!_vm.collapsed)?_c(VIcon,[_vm._v("mdi-chevron-up")]):_vm._e()],1)],1),(!_vm.collapsed)?_c(VCardText,{staticClass:"pa-0",on:{"click":function($event){return _vm.$emit('click:details')}}},[_c(VList,{staticClass:"pa-0",attrs:{"dense":""}},[(_vm.driver)?_c(VListItem,{staticStyle:{"font-size":"10pt","padding":"0"}},[_c(VIcon,{staticClass:"mx-1"},[_vm._v("mdi-moped")]),_vm._v(" "+_vm._s(_vm.driver.name)+" ")],1):_vm._e(),(_vm.driver)?_c(VDivider):_vm._e(),_c(VListItem,{staticStyle:{"font-size":"10pt","padding":"0"}},[_c(VListItemContent,{staticClass:"d-flex align-baseline py-0"},[_c('table',{staticClass:"delivery-table"},[_c('tr',{staticStyle:{"background":"#fff8af"}},[_c('td',[_vm._v("No.")]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.order.id)+" ")])]),_c('tr',[_c('td',[_vm._v("Price:")]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.order.orderPrice.toLocaleString({ minimumFractionDigits: 2, }) || "-")+" ")])]),_c('tr',[_c('td',[_vm._v("Items:")]),_c('td',{staticStyle:{"text-align":"right"}},[_c(VChip,{staticClass:"px-1",staticStyle:{"max-height":"18px"},attrs:{"label":"","color":"light-grey"}},[_vm._v(_vm._s(_vm.order.items.length))])],1)]),_c('tr',[_c('td',[_vm._v("Time:")]),_c('td',{staticStyle:{"text-align":"right"}},[_c(VChip,{staticClass:"px-1",staticStyle:{"max-height":"18px"},attrs:{"small":"","label":"","dark":"","color":_vm.days > 0
                      ? 'gray lighten-1'
                      : _vm.hours === 0
                      ? 'green lighten-2'
                      : _vm.hours === 1
                      ? 'orange lighten-2'
                      : 'red'}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.getOrderTime))]),_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-clock-time-eight ")])],1)],1)])])])],1)],1)],1):_vm._e(),(!_vm.collapsed)?_c(VCardActions,{staticClass:"pa-0",staticStyle:{"height":"20px"}},[(_vm.order.activeTrackStatus !== 'WAITING')?_c(VBtn,{attrs:{"small":"","color":"orange","icon":"","dark":"","depressed":"","tile":""},on:{"click":function($event){return _vm.$emit('click:back')}}},[_c(VIcon,[_vm._v(_vm._s(_vm.backIcon || "mdi-skip-previous"))])],1):_vm._e(),(_vm.order.activeTrackStatus !== 'WAITING')?_c(VSpacer):_vm._e(),_c(VBtn,{attrs:{"small":"","icon":"","color":"red lighten-2"},on:{"click":function($event){_vm.order.activeTrackStatus === 'WAITING'
          ? _vm.$emit('click:reject')
          : _vm.$emit('click:cancel')}}},[_c(VIcon,[_vm._v("mdi-stop")])],1),(
        !['LOADING', 'DISPATCHED', 'DELIVERY', 'NEAR'].includes(
          _vm.order.activeTrackStatus
        )
      )?_c(VSpacer):_vm._e(),(
        !['LOADING', 'DISPATCHED', 'DELIVERY', 'NEAR'].includes(
          _vm.order.activeTrackStatus
        )
      )?_c(VBtn,{attrs:{"small":"","color":"green","icon":"","dark":"","depressed":"","tile":""},on:{"click":function($event){return _vm.$emit('click:next')}}},[_c(VIcon,[_vm._v("mdi-skip-next")])],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }