import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,{staticStyle:{"flex-wrap":"nowrap"},attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"flex-grow-0 flex-shrink-0 text-center",attrs:{"cols":"1"}},[(!_vm.mine && !_vm.sameSource)?_c(VCard,{staticClass:"text-center",attrs:{"flat":""}},[_c(VAvatar,{attrs:{"color":"secondary","size":"56"}},[_c(VImg,{attrs:{"src":_vm.getAvatar}})],1),_c('div',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.username))])],1):_vm._e(),_c(VSpacer)],1),_c(VCol,{class:("flex-grow-1 flex-shrink-0 " + (_vm.mine ? 'mb-1' : 'mb-2')),style:(("min-width: 100px; max-width: 100%; display: flex; " + (_vm.mine ? 'flex-direction: row-reverse' : '') + ";")),attrs:{"cols":"10"}},[_c(VCard,{class:("d-flex align-start pa-2 " + (_vm.mine ? 'mr-3' : 'ml-3')),attrs:{"flat":"","color":_vm.getColor}},[(!_vm.mine && !_vm.sameSource)?_c(VIcon,{staticStyle:{"position":"absolute","left":"-20px"},attrs:{"large":"","color":_vm.getColor}},[_vm._v(" mdi-menu-left ")]):_vm._e(),_c('div',{staticClass:"px-1 pb-1"},[_vm._v(" "+_vm._s(_vm.content)+" ")]),_c('div',{class:(_vm.getTimeColor + " mt-auto"),staticStyle:{"font-size":"8pt","margin-bottom":"-8px","margin-left":"-8px"}},[_vm._v(_vm._s(_vm.getTime))]),(_vm.mine && !_vm.sameSource)?_c(VIcon,{staticStyle:{"position":"absolute","right":"-20px"},attrs:{"large":"","color":_vm.getColor}},[_vm._v(" mdi-menu-right ")]):_vm._e()],1)],1),_c(VCol,{staticClass:"flex-grow-0 flex-shrink-0 text-center d-flex flex-column",attrs:{"cols":"1"}},[(_vm.mine && !_vm.sameSource)?_c(VCard,{staticClass:"text-center",attrs:{"flat":""}},[_c(VAvatar,{attrs:{"color":"secondary","size":"56"}},[_c(VImg,{attrs:{"src":_vm.getAvatar}})],1),_c('div',{staticClass:"body-2"},[_vm._v("username"+_vm._s(_vm.username))])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }