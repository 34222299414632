<template>
  <div v-if="active" class="typing-dots">
    <div :class="`typing-dot ${color}`"></div>
    <div :class="`typing-dot ${color}`"></div>
    <div :class="`typing-dot ${color}`"></div>
  </div>
</template>

<script>
export default {
  name: "TypingDots",
  props: {
    active: Boolean,
    color: {
      type: String,
      default() {
        return "black";
      },
    },
  },
};
</script>

<style>
.typing-dots {
  position: absolute;
  margin-top: -100px;
  margin-left: 30px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  animation: dots 1s ease-in-out infinite;
}

.typing-dot {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  border-radius: 50%;
  animation: dot 1s ease-in-out infinite;
  background-color: #000;
}
.typing-dot:nth-child(1) {
  animation-delay: 0s;
}
.typing-dot:nth-child(2) {
  animation-delay: 0.25s;
}
.typing-dot:nth-child(3) {
  animation-delay: 0.5s;
}

@keyframes dots {
  0%,
  20% {
    opacity: 0.2;
  }
  0% {
    opacity: 1;
  }
}

@keyframes dot {
  0%,
  20% {
    transform: scale(0.2);
  }
  0% {
    transform: scale(1);
  }
}
</style>