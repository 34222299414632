<template>
  <div
    v-if="temporary"
    style="position: absolute; width: 100%; height: 100%; z-index: 1;"
  >
    <v-navigation-drawer
      v-model="toggledDrawerState"
      :right="!getLocale.rtl"
      absolute
      fixed
      width="390"
    >
      <v-container class="pa-0" style="margin-bottom: 50px;">
        <slot></slot>
      </v-container>
    </v-navigation-drawer>

    <div
      v-if="temporary"
      style="position: fixed; width: 100%; height: 100%; background-color: #000; opacity: 0.1"
      @click="closeDrawer"
    ></div>
  </div>
</template>

<script>
export default {
  name: "AppDrawer",
  model: {
    prop: "drawer",
    event: "input",
  },
  props: {
    drawer: Boolean,
  },
  watch: {
    drawer: {
      handler: function(n) {
        this.temporary = n;
      },
    },
  },
  data() {
    return {
      temporary: false,
    };
  },
  // computed: {
  //   getDrawer: {
  //     get() {
  //       return this.drawer;
  //     },
  //     set(value) {
  //       this.drawer = value;
  //       this.$emit("update:drawer", value);
  //     },
  //   },
  // },
  computed: {
    toggledDrawerState: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit("update:drawer", value);
      },
    },
  },
  methods: {
    closeDrawer() {
      this.temporary = false;
      this.$emit("closed");
    },
  },
};
</script>
