<template>
  <v-card flat>
    <v-skeleton-loader v-if="!order" type="actions, card, table" />

    <v-tabs v-if="order" v-model="tabs" background-color="grey lighten-3">
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-receipt" class="primary--text" style="min-width: 60px">
        <v-icon>mdi-cart</v-icon>
      </v-tab>

      <!-- <v-tab href="#tab-map" class="primary--text">
        <v-icon>mdi-map-marker</v-icon>
      </v-tab> -->

      <v-tab
        v-if="driver"
        href="#tab-driver"
        class="primary--text"
        style="min-width: 60px"
        @click="$router.replace({ query: { tab: 'driver' } })"
      >
        <v-icon>mdi-truck-delivery</v-icon>
      </v-tab>

      <v-tab
        v-if="support"
        href="#tab-support"
        class="primary--text"
        style="min-width: 60px"
      >
        <v-icon>mdi-headset</v-icon>
      </v-tab>
      
      <v-tab
        v-if="history"
        href="#tab-history"
        class="primary--text"
        style="min-width: 60px"
        @click="$router.replace({ query: { tab: 'history' } })"
      >
        <v-icon>mdi-history</v-icon>
      </v-tab>

      <v-divider vertical />

      <v-tab
        v-if="customer"
        href="#tab-customer"
        class="primary--text"
        style="min-width: 60px"
        @click="$router.replace({ query: { tab: 'customer' } })"
      >
        <v-icon>mdi-account</v-icon>
      </v-tab>

      <v-tab
        v-if="customer"
        href="#tab-customer-driver-chat"
        class="primary--text"
        style="min-width: 60px"
        @click="$router.replace({ query: { tab: 'customer-driver-chat' } })"
      >
        <v-icon>mdi-tooltip-account</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-if="order" v-model="tabs">
      <v-tab-item value="tab-receipt">
        <sticky-stack-layout
          :offset="124"
          :header-height="48"
          :footer-height="183"
          :style="getViewHeightStyle(124)"
        >
          <template v-slot:header>
            <v-toolbar flat short dense color="grey lighten-5">
              <v-spacer />
              <v-divider vertical inset class="mx-2" />
              <v-btn fab small tile depressed>
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-divider vertical inset class="mx-2" />
              <v-btn fab small tile depressed>
                <v-icon>mdi-share-variant</v-icon>
              </v-btn>
              <v-divider vertical inset class="mx-2" />
              <v-btn fab small tile depressed>
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </v-toolbar>
          </template>

          <section-header class="px-2" hide-divider title="Items List" />
          <order-items v-model="getItems" :currency="getCurrency" />

          <template v-slot:footer>
            <section-header class="px-2" title="Summary" />
            <order-summary v-model="order" :currency="getCurrency" />
          </template>
        </sticky-stack-layout>
      </v-tab-item>
      <!-- <v-tab-item value="tab-map">
        <v-card flat>
          <v-card-text>Map</v-card-text>
        </v-card>
      </v-tab-item> -->
      <v-tab-item v-if="customer" value="tab-customer">
        <v-card flat>
          <v-card-title>
            <v-avatar size="56">
              <img alt="customer" :src="getCustomerAvatar" />
            </v-avatar>
            <p class="mx-1 mb-0">{{ order.customerName }}</p>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col><v-icon>mdi-map-marker</v-icon> Address:</v-col>
              <v-col>{{ order.customerAddress }}</v-col>
            </v-row>
            <v-row>
              <v-col><v-icon>mdi-phone</v-icon> Phone:</v-col>
              <v-col class="text-right">{{ order.customerPhone }}</v-col>
            </v-row>
            <v-divider class="my-3" />
            <v-card-actions>
              <v-btn depressed>
                <v-icon>mdi-phone-dial</v-icon>
              </v-btn>
              <v-btn depressed @click="beepAlert(order.customerUuid)">
                <v-icon>mdi-bullhorn</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item v-if="driver" value="tab-driver">
        <v-card flat>
          <v-card-title>
            <v-avatar size="56">
              <img alt="customer" :src="getDriverAvatar" />
            </v-avatar>
            <p class="mx-1 mb-0">{{ order.driverName }}</p>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col><v-icon>mdi-phone</v-icon> Phone:</v-col>
              <v-col class="text-right">{{ order.driverPhone }}</v-col>
            </v-row>
            <v-divider class="my-3" />
            <v-card-actions>
              <v-btn depressed>
                <v-icon>mdi-phone-dial</v-icon>
              </v-btn>
              <v-btn depressed @click="beepAlert(order.driverUuid)">
                <v-icon>mdi-bullhorn</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item v-if="history" value="tab-history">
        <v-timeline align-top dense>
          <v-timeline-item
            :color="getStatusTypeColor(track.status)"
            small
            v-for="(track, i) in order.tracks"
            :key="i"
          >
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>{{ track.status }}</strong>
                <p>{{ formatTime(track.time) }}</p>
              </v-col>
              <!-- <v-col>
                <strong>New Icon</strong>
                <div class="caption">
                  Mobile App
                </div>
              </v-col> -->
            </v-row>
          </v-timeline-item>

          <!-- <v-timeline-item color="teal lighten-3" small>
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>3-4pm</strong>
              </v-col>
              <v-col>
                <strong>Design Stand Up</strong>
                <div class="caption mb-2">
                  Hangouts
                </div>
                <v-avatar>
                  <v-img
                    src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown"
                  ></v-img>
                </v-avatar>
                <v-avatar>
                  <v-img
                    src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned"
                  ></v-img>
                </v-avatar>
                <v-avatar>
                  <v-img
                    src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairMiaWallace&accessoriesType=Sunglasses&hairColor=BlondeGolden&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Surprised&eyebrowType=RaisedExcited&mouthType=Smile&skinColor=Pale"
                  ></v-img>
                </v-avatar>
              </v-col>
            </v-row>
          </v-timeline-item> -->
        </v-timeline>
      </v-tab-item>

      <v-tab-item v-if="support" value="tab-support">
      </v-tab-item>

      <v-tab-item v-if="customer" value="tab-customer-driver-chat">
        <chat-box
          :style="getViewHeightStyle(124)"
          :room="getRoomUuid(order.uuid, 'dlvr')" :room-type="roomTypes.ROOM_DELIVERY"
          :participants="[order.customerUuid, order.driverUuid]"
          />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import SectionHeader from "@/common/components/SectionHeader";
import ChatBox from "@/common/components/ChatBox";
import OrderItems from "@/views/orders/OrderItems";
import OrderSummary from "@/views/orders/OrderSummary";
import mixin from "@/views/orders/orders.mixin";
import { mapActions } from "vuex";
import StickyStackLayout from "@/common/layouts/StickyStackLayout";
import { ROOM_DELIVERY } from "@/store/actions/type.chat";

export default {
  name: "OrderDetails",
  mixins: [mixin],
  components: {
    SectionHeader,
    OrderItems,
    OrderSummary,
    ChatBox,
    StickyStackLayout,
  },
  model: {
    prop: "order",
    event: "input",
  },
  props: {
    order: Object,
    customer: Boolean,
    driver: Boolean,
    history: Boolean,
    support: Boolean,
  },
  data() {
    return {
      tabs: null,
      message: "",
      roomTypes: {
        ROOM_DELIVERY
      },
      messages: [
        {
          sender: "User 1",
          message: "Hello, how are you?",
        },
        {
          sender: "User 2",
          message: "I'm good, thanks for asking! How about you?",
        },
      ],
    };
  },
  mounted() {
    this.tabs = "tab-" + this.$route.query.tab;
    // console.log(this.order);
  },
  computed: {
    getCustomerAvatar() {
      return `${this.getApiBaseUrl}/files/avatar/${this.order?.customerUuid}`;
    },
    getDriverAvatar() {
      return `${this.getApiBaseUrl}/files/avatar/${this.order?.driverUuid}`;
    },
    getItems() {
      return this.order
        ? this.order.items.map((m) => ({
            ...m,
            name: m.productName,
            nameAr: m.productNameAr,
          }))
        : null;
    },
    getCurrency() {
      if (
        this.order &&
        this.order.address &&
        this.order.address.store &&
        this.order.address.store.region
      ) {
        return this.order.address.store.region.currency;
      }
      return "YE";
    },
    tabParam() {

    }
  },
  methods: {
    ...mapActions("users", ["beepAlert"]),

    getRoomUuid(uuId, sugment) {
        const segments = uuId.split("-");
        segments[3] = sugment;
        return segments.join("-");
    }
  },
};
</script>
